<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-6 c g">
            <div class="card card-body">
                <button class="btn btn-block btn-lg btn-success" @click="check()" v-if="!checking">
                    <i class="fa fa-external-link"></i>
                    الذهاب لنظام مصروفي
                </button>
                <button class="btn btn-block btn-lg btn-outline-success" v-if="checking" style="padding: 0px !important">
                    <img :src="require('@/assets/images/loading-bar.svg')" alt="">
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            checking: false,
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    methods: {
        check(){
            var g = this;
            g.checking = true;
            $.post(api + '/user/general/masrufi', {
                jwt: this.user.jwt
            }).then(function(r){
                g.checking = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    window.open(`https://masrufi.brmja.com/autologin/${r.response}`)
                }
            }).fail(function(){
                g.checking = false
            })
        }
    }
}
</script>

<style>

</style>